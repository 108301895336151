import { baseHTTP, baseDownloadHTTP } from "../http";
import store from "@/store";

export default {
  // TODO: Isso veio na junção do piedAdmin com o piedDist, verificar se ainda precisa disso
  // async register(name,surname,email,password,cnpj,razaoSocial,fantasyName,emailResponsavelFinanceiro,cep,patio,neighborhood,city,state,number,complement,telefone,telefone2){
  //   let data = {
  //     distribuitorAdmin: {
  //       name: name,
  //       surname: surname,
  //       email: email,
  //       password: password,
  //     },
  //     distribuitorInfo: {
  //       CNPJ: cnpj,
  //       razaoSocial: razaoSocial,
  //       nomeFantasia: fantasyName,
  //       telefone: telefone,
  //       telefone2: telefone2,
  //       emailResponsavelFinanceiro: emailResponsavelFinanceiro,
  //       address: {
  //         CEP: cep,
  //         patio: patio,
  //         neighborhood: neighborhood,
  //         city: city,
  //         state: state,
  //         number: number,
  //         complement: complement,
  //       },
  //     },
  //   }
  //   return await baseHTTP('post', "/app/open/register", data, false)
  // },

  async login(login, password) {
    let data = {
      login,
      password,
    };
    const ret = await baseHTTP("post", `/app/login`, data, false);
    if (!ret.error && ret.data) {
      await store.dispatch("userData/setUserData", ret.data);
    }
    return ret;
  },

  async loginAdm(login, password) {
    let data = {
      login,
      password,
    };
    const ret = await baseHTTP("post", `/app/login/admin`, data, false);
    if (!ret.error && ret.data) {
      await store.dispatch("userData/setUserData", ret.data);
    }
    return ret;
  },

  async loginWithToken(token) {
    const ret = await baseHTTP("post", `/app/loginWithToken`, token, false);
    if (!ret.error && ret.data) {
      await store.dispatch("userData/setUserData", ret.data);
    }
    return ret;
  },

  async refreshSession(data) {
    return baseHTTP("post", `/app/refreshSession`, data);
  },

  async integratorRegister(data) {
    return baseHTTP("post", `/app/open/integrator-register`, data, false);
  },

  async readTheme() {
    return baseHTTP("get", `/app/open/theme`, null, false);
  },

  async readAllowedResponsibleSellers(distribuitorId) {
    return baseHTTP(
      "get",
      `/app/open/allowedResponsibleSellers/${distribuitorId}`,
      null,
      false
    );
  },

  async readInteractiveProposal(leadToken, proposalId) {
    return baseHTTP(
      "get",
      `/app/open/interactiveProposal/${leadToken}/${proposalId}`,
      null,
      false
    );
  },

  /**
   * Recupera a senha do usuario.
   * @param {String} email Email para qual será enviado a nova senha casa esteja cadastrado
   */
  async recoveryPassword(email) {
    return baseHTTP("get", `/app/open/recoverPassword/${email}`, null, false);
  },

  downloadTerms() {
    return baseDownloadHTTP("get", null, true, "/app/open/download/terms");
  },

  readOrderDetails(orderId) {
    return baseHTTP("get", `/app/open/order/${orderId}/details`);
  },

  checkStatusAndGetDetailPayment(
    orderId,
    payload = {
      ifExpiredGenerateNew: false,
      paymentMethod: "credito",
    }
  ) {
    const { ifExpiredGenerateNew, paymentMethod } = payload;

    return baseHTTP("get", `/app/open/order/${orderId}/status`, {
      ifExpiredGenerateNew,
      paymentMethod,
    });
  },

  createPayment(orderId, payload) {
    return baseHTTP("post", `/app/open/payment/${orderId}/create`, payload);
  },
};
