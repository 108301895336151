import { baseDownloadHTTP, baseHTTP } from "../http";
import store from "@/store";
import { CreateTransaction } from "@/plugins/sentry";
import { VIEW_MODE_LIST } from "@/store/modules/requests";
import { PaymentMethodEnum } from "@/views/requests/class/PaymentDetail";

export default {
  async downloadDatasheet(id, docName) {
    return baseDownloadHTTP(
      "get",
      docName + ".pdf",
      true,
      `/app/user/product/downloadDatasheet/${id}`,
      null
    );
  },

  async downloadRequestPaymentFiles(id, name, filePath) {
    return baseDownloadHTTP(
      "get",
      name,
      true,
      `/app/user/download/request/${id}/payment`,
      { filePath }
    );
  },

  async downloadRequestFiles(name, data) {
    return baseDownloadHTTP(
      "post",
      name,
      true,
      `/app/user/download/request/files`,
      data
    );
  },

  async downloadPdfOrDoc(requestType, id, code, extension) {
    return baseDownloadHTTP(
      "get",
      `${requestType === "order" ? "Pedido" : "Orçamento"} ${code}.${
        extension === "doc" ? "docx" : "pdf"
      }`,
      true,
      `/app/user/download/${requestType}/${id}/${extension}`,
      null
    );
  },

  async logout() {
    let ret = await baseHTTP("post", `/app/user/logout`, null);
    if (!ret.error) await store.dispatch("userData/removeUserData");
  },

  async genSingleSignOnToken() {
    let ret = await baseHTTP("get", `/app/user/genSingleSignOnToken`, null);
    if (!ret.error && ret.data) return ret;
  },

  async readStatusRestrictOptions() {
    return baseHTTP("get", `/app/user/readStatusRestrictOptions`);
  },

  async readCitiesByState(state) {
    return baseHTTP("post", `/app/user/cities/`, { state });
  },

  async readDashboardData(dates, isToShowAdvancedDashboard = false) {
    return baseHTTP("get", `/app/user/dashboard`, {
      dates,
      isToShowAdvancedDashboard,
    });
  },

  async readDashboardByType(filter = {}) {
    return baseHTTP("post", `/app/user/dashboard`, filter);
  },

  async readDiscountSetting() {
    return baseHTTP("get", `/app/user/discountSetting`);
  },

  async readExtraItem(purchaseType = "") {
    return baseHTTP("get", `/app/user/extraItem/${purchaseType}`);
  },

  async readCheckoutLoad(purchaseType = "") {
    return baseHTTP("get", `/app/user/checkoutLoad/${purchaseType}`);
  },

  async readProductsBaseLoad(payload) {
    const params = payload?.ecommerce ? { ecommerce: true } : "";
    return baseHTTP(
      "get",
      `/app/user/productsBaseLoad/${payload.purchaseType || ""}/${
        payload.eventType || ""
      }`,
      params
    );
  },

  async readSizingSettings() {
    return baseHTTP("get", `/app/user/settings/sizing`);
  },

  async readCustomForm() {
    return baseHTTP("get", `/app/user/customForm`);
  },

  async readConfigCheckoutFile() {
    return baseHTTP("get", `/app/user/configCheckoutFile`);
  },

  async readAnswersCustomForm(requestId) {
    return baseHTTP("get", `/app/user/answersCustomForm/${requestId}`);
  },

  async readIrradiation(state, city) {
    return baseHTTP("post", `/app/user/irradiation/`, { state, city });
  },

  async readTariff(state) {
    return baseHTTP("post", `/app/user/tariff/`, { state });
  },

  async readDatasheets() {
    return baseHTTP("get", `/app/user/datasheets`);
  },

  async readInvertersType() {
    return baseHTTP("get", `/app/user/invertersTypes`);
  },

  async sizingFast(
    valorFatura,
    siglaEstado,
    valorTarifa,
    latitude,
    irradiacao_media_diaria,
    service
  ) {
    let data = {
      valorFatura,
      siglaEstado,
      valorTarifa,
      latitude,
      irradiacao_media_diaria,
    };
    return baseHTTP("post", `/app/user/sizing/fast/${service}`, data);
  },

  async sizing(
    grupo_tarifario,
    subgrupo,
    tipo_tarifacao,
    tipo_ligacao,
    tensao_abastecimento,
    consumo_medio_mensal_irrigacao,
    disjuntor_padrao_entrada,
    consumo_medio_mensal,
    tarifa_convencional,
    tarifa_irrigacao,
    consumo_ponta,
    tarifa_ponta,
    consumo_intermediario,
    tarifa_intermediario,
    consumo_fora_ponta,
    tarifa_fora_ponta,
    latitude,
    irradiacao_media_diaria,
    area_disponivel,
    consumo_medio_mensal_fora_ponta,
    consumo_medio_mensal_ponta,
    demanda_contratada,
    demanda_contratada_fora_ponta,
    potencia_instalada,
    tarifa_TE_fora_ponta,
    tarifa_TE_irrigacao,
    tarifa_TE_ponta,
    tarifa_TUSD_fora_ponta,
    tarifa_TUSD_irrigacao,
    tarifa_TUSD_ponta,
    service
  ) {
    let data = {
      grupo_tarifario,
      subgrupo,
      tipo_tarifacao,
      tipo_ligacao,
      tensao_abastecimento,
      consumo_medio_mensal_irrigacao,
      disjuntor_padrao_entrada,
      consumo_medio_mensal,
      tarifa_convencional,
      tarifa_irrigacao,
      consumo_ponta,
      tarifa_ponta,
      consumo_intermediario,
      tarifa_intermediario,
      consumo_fora_ponta,
      tarifa_fora_ponta,
      latitude,
      irradiacao_media_diaria,
      area_disponivel,
      consumo_medio_mensal_fora_ponta,
      consumo_medio_mensal_ponta,
      demanda_contratada,
      demanda_contratada_fora_ponta,
      potencia_instalada,
      tarifa_TE_fora_ponta,
      tarifa_TE_irrigacao,
      tarifa_TE_ponta,
      tarifa_TUSD_fora_ponta,
      tarifa_TUSD_irrigacao,
      tarifa_TUSD_ponta,
    };
    return baseHTTP("post", `/app/user/sizing/${service}`, data);
  },

  async updateUserNotificationLookedAt(payload) {
    return baseHTTP(
      "put",
      `/app/user/userNotification/updateLookedAt`,
      payload
    );
  },

  async changePasswordIntegrator(currentPassword, newPassword) {
    return baseHTTP("post", `/app/user/password/?isIntegrator=1`, {
      currentPassword,
      newPassword,
    });
  },

  async changePassword(currentPassword, newPassword) {
    return baseHTTP("post", `/app/user/password`, {
      currentPassword,
      newPassword,
    });
  },

  async errorReport(description, name, data, exception = null) {
    const trx = CreateTransaction("errorReport", {
      name,
      description,
      data,
    });

    if (exception) trx.exception(exception);
    trx.save();
  },

  async readRequestProposalData(requestId) {
    return baseHTTP("get", `/app/user/request/proposalData/${requestId}`);
  },

  async readRequestById({ requestId, query = {}, origin = null }) {
    return baseHTTP("get", `/app/user/request/readById`, {
      ...query,
      requestId: requestId || query.requestId,
      origin,
    });
  },

  async readCompanyInfo(id = "") {
    return baseHTTP("get", `/app/user/companyInfo/${id}`);
  },

  async readIntegratorById(id) {
    return baseHTTP("get", `/app/user/integrator/byId/${id}`);
  },

  async readIntegratorBudgets(id, page, limit) {
    return baseHTTP(
      "get",
      `/app/user/integrator/budget/${id}/${page}/${limit}`
    );
  },

  async readOrderMessages() {
    return baseHTTP("get", `/app/user/orderMessages`);
  },

  async readIntegratorOrders(id, page, limit) {
    return baseHTTP("get", `/app/user/integrator/order/${id}/${page}/${limit}`);
  },

  async getIntegratorState(id = "") {
    return baseHTTP("get", `/app/user/getIntegratorState/${id}`);
  },

  async readMessages(group = "all") {
    return baseHTTP("get", `/app/user/readMessages/${group}`);
  },

  async readDistributorProcessedFormulas() {
    return baseHTTP("get", `/app/user/distributorFormulas`);
  },

  async readDelivery(payload = []) {
    let formatedPayload = null,
      searchParams = null;
    if (payload.length) {
      formatedPayload = payload.reduce((acc, cur) => {
        return `${acc}${acc === "search=" ? "" : "+"}${cur}`;
      }, "search=");

      searchParams = new URLSearchParams(formatedPayload).toString();
    }
    return baseHTTP("get", `/app/user/delivery-states/${searchParams}`);
  },
  async readSideBar() {
    return baseHTTP("get", `/app/user/sideBar`);
  },

  async readStructureName() {
    return baseHTTP("get", `/app/user/structureName`);
  },

  async readMountKitSettings() {
    return baseHTTP("get", `/app/user/mount-kit/settings`);
  },

  async readRequestFiles(requestId) {
    return baseHTTP("get", `/app/user/request/${requestId}/files`);
  },

  async readFileCategory(page, limit) {
    return baseHTTP("get", `/app/user/file-category/${page}/${limit}`);
  },

  async readKitsToBuy(query) {
    return baseHTTP("get", `/app/user/sale/kit/`, query);
  },

  async createRequest(requestType, integrator, data) {
    return baseHTTP(
      "post",
      `/app/user/checkout/${requestType}/${integrator}`,
      data
    );
  },

  async importRequestFiles(requestId, requestFiles, configCheckoutFileId = "") {
    const formData = new FormData();
    requestFiles
      .filter((item) => item.length)
      .forEach((files) => {
        files.forEach((file) => {
          formData.append("requestFiles", file);
          formData.append(
            "configCheckoutFileId",
            file.configFileId || configCheckoutFileId
          );
        });
      });
    return baseHTTP(
      "put",
      `/app/user/request/uploadFiles/${requestId}${
        configCheckoutFileId ? "/" + configCheckoutFileId : ""
      }`,
      formData
    );
  },

  async createOrderFromBudget(id, data) {
    return baseHTTP(
      "post",
      `/app/user/budget/create-order-from-budget/${id}`,
      data
    );
  },

  async editRequestFreight(id, data) {
    return baseHTTP("post", `/app/user/request/editFreight/${id}`, data);
  },

  async readSaleSetting() {
    return baseHTTP("get", `/app/user/saleSetting`);
  },

  async readIntegratorSettings() {
    return baseHTTP("get", `/app/user/integrator-settings`);
  },

  async readDistributorSettings() {
    return baseHTTP("get", `/app/user/distributorSettings`);
  },

  async readDistributorApiSettings() {
    return baseHTTP("get", `/app/user/distributorApiSettings`);
  },

  async readEcommerceSettings() {
    return baseHTTP("get", `/app/user/ecommerceSettings`);
  },

  async readKitSettings() {
    return baseHTTP("get", `/app/user/kit-settings`);
  },

  async readShoppingSettings() {
    return baseHTTP("get", `/app/user/shopping-settings`);
  },

  async readActivatedTemplates() {
    return baseHTTP("get", `/app/user/activatedTemplates`);
  },

  async readIndicatorEditedRequestRecommendation() {
    return baseHTTP(
      "get",
      `/app/user/readIndicatorEditedRequestRecommendation`
    );
  },

  async readRequest(
    type = "order",
    mode = VIEW_MODE_LIST,
    page = 1,
    limit = 10,
    query = {}
  ) {
    return baseHTTP(
      "get",
      `/app/user/request/${type}/${mode || VIEW_MODE_LIST}`,
      {
        page,
        limit,
        ...query,
      }
    );
  },

  async readRequestStatus(data) {
    return baseHTTP(
      "get",
      `/app/user/requestStatus/${data.type}/${data.distributorId || ""}`
    );
  },

  findProductHighlights(query = {}) {
    return baseHTTP("get", "/app/user/productHighlights", query);
  },

  async readAdvancedDashboardSettings() {
    return baseHTTP("get", `/app/user/settings/advancedDashboard`);
  },

  async updateAdvancedDashboardSettings(data) {
    return baseHTTP("put", `/app/user/settings/advancedDashboard`, data);
  },

  async readCrmSettings() {
    return baseHTTP("get", `/app/user/settings/crmModule`);
  },

  async readBanks(cameFrom) {
    return baseHTTP("get", `/app/user/settings/crmModule/bank/${cameFrom}`);
  },

  async createBank(data) {
    return baseHTTP("post", `/app/user/settings/crmModule/bank`, data);
  },

  async createFinancingLine(data) {
    return baseHTTP("post", `/app/user/settings/crmModule/financingLine`, data);
  },

  async updateBank(data) {
    return baseHTTP("put", `/app/user/settings/crmModule/bank`, data);
  },

  async updateFinancingLine(data) {
    return baseHTTP("put", `/app/user/settings/crmModule/financingLine`, data);
  },

  async updateUserInfo(data) {
    return baseHTTP("put", `/app/user/info`, data);
  },

  async readAdvertisingPopUp(page = 1, limit = 50) {
    return baseHTTP("get", `/app/user/advertisingPopUp/${page}/${limit}`);
  },

  async readDashboard(query) {
    return baseHTTP("get", `/app/user/dashboard`, query);
  },

  async readBannersByRoute(route) {
    return baseHTTP("get", `/app/user/bannerGroups/${route}`);
  },

  async deleteBank(bankId) {
    return baseHTTP("delete", `/app/user/settings/crmModule/bank/${bankId}`);
  },

  deleteCoupon(id) {
    return baseHTTP("delete", `/app/user/coupon/${id}`);
  },

  async deleteFinancingLine(financingLineId) {
    return baseHTTP(
      "delete",
      `/app/user/settings/crmModule/financingLine/${financingLineId}`
    );
  },

  async deleteRequestFiles(requestId, configCheckoutFileId) {
    return baseHTTP(
      "delete",
      `/app/user/requestFiles/${requestId}/${configCheckoutFileId}`
    );
  },

  async reloadUserData() {
    return baseHTTP("get", `/app/user/reloadUserData`, null);
  },

  async readBudgetSetting() {
    return baseHTTP("get", `/app/user/budget-setting`);
  },

  async calculateFreightPrice(data) {
    return baseHTTP("post", `/app/user/price/freight`, data);
  },

  async calculateCompanyServicePrice(data) {
    return baseHTTP("post", `/app/user/price/companyService`, data);
  },

  async calculateDynamicRecommendations(data) {
    return baseHTTP(
      "post",
      `/app/user/request/calculateDynamicRecommendations`,
      data
    );
  },

  async calculateAutomaticInverterRecommendations(data) {
    return baseHTTP(
      "post",
      `/app/user/request/calculateAutomaticInverterRecommendations`,
      data
    );
  },

  async calculateExtraItemPrices(data) {
    return baseHTTP("post", `/app/user/price/extraItem`, data);
  },

  async calculateMultipleKitPrices(data) {
    return baseHTTP("post", `/app/user/price/kit`, data);
  },

  async checkStock(
    shippingState,
    newProducts,
    oldProducts = [],
    isBudget = false
  ) {
    return baseHTTP("post", `/app/user/request/checkStock`, {
      shippingState,
      newProducts,
      oldProducts,
      isBudget,
    });
  },

  async checkCompanyDocument(data) {
    return baseHTTP("get", "app/user/integrator/checkCompanyDocument", data);
  },

  supportAutoComplete(type, query = {}) {
    return baseHTTP("get", `/app/user/support/autoComplete/${type}`, query);
  },

  readCoupons(filter = {}) {
    return baseHTTP("get", `/app/user/coupon`, filter);
  },

  readCoupon(id) {
    return baseHTTP("get", `/app/user/coupon/${id}`);
  },

  readCouponUtilization(id) {
    return baseHTTP("get", `/app/user/coupon/${id}/utilization`);
  },

  saveCoupon(data = {}) {
    if (data?.id) {
      return baseHTTP("put", `/app/user/coupon/${data.id}`, data);
    }

    return baseHTTP("post", `/app/user/coupon`, data);
  },

  async exportCoupon(id, code) {
    return baseDownloadHTTP(
      "get",
      code + ".xlsx",
      true,
      `/app/user/coupon/${id}/export`,
      null
    );
  },

  async applyCoupon(data) {
    return baseHTTP("post", "/app/user/request/applyCoupon", data);
  },

  async checkCouponAvailability(coupon, query) {
    return baseHTTP(
      "get",
      `/app/user/coupon/${coupon}/checkAvailability`,
      query
    );
  },

  async possibleInverterFilters(query = {}) {
    return baseHTTP(
      "get",
      `/app/user/request/kitExpress/possibleInverterFilters`,
      query
    );
  },

  async purchaseProducts(purchaseType, productType) {
    return baseHTTP("get", `/app/user/request/${purchaseType}/products`, {
      type: productType,
    });
  },

  async recommendations(filter) {
    return baseHTTP(
      "get",
      `/app/user/request/kitExpress/recommendations`,
      filter
    );
  },

  async npsPostpone(npsId) {
    return baseHTTP("put", `/app/user/nps/${npsId}/postpone`);
  },

  async npsAnswer(
    npsId,
    { scoreValue, positiveFeedback = "", negativeFeedback = "", commentary }
  ) {
    return baseHTTP("put", `/app/user/nps/${npsId}/answer`, {
      scoreValue,
      positiveFeedback,
      negativeFeedback,
      commentary,
    });
  },
  async readSeparatedSalesSections() {
    return baseHTTP("get", `/app/user/separatedSalesSections`);
  },

  async comments(id, type, page = 1, limit = 10) {
    return baseHTTP("get", `/app/user/comments/${page}/${limit}`, {
      parentId: id,
      parentType: type,
    });
  },

  async createComment(id, type, message) {
    return baseHTTP("post", `/app/user/comments`, {
      parentId: id,
      parentType: type,
      message: message,
    });
  },

  async updateComment(id, message, fixed = false) {
    return baseHTTP("put", `/app/user/comments/${id}`, {
      message: message,
      fixed: fixed,
    });
  },

  async deleteComment(id) {
    return baseHTTP("delete", `/app/user/comments/${id}`);
  },

  async createReplyComment(commentId, message) {
    return baseHTTP("post", `/app/user/comments/${commentId}/reply`, {
      message: message,
    });
  },

  async updateReplyComment(commentId, id, message) {
    return baseHTTP("put", `/app/user/comments/${commentId}/reply/${id}`, {
      message: message,
    });
  },

  async deleteReplyComment(commentId, id) {
    return baseHTTP("delete", `/app/user/comments/${commentId}/reply/${id}`);
  },

  async readPaymentDetail(orderId) {
    return baseHTTP("get", `/app/user/payment/${orderId}/detail`);
  },

  async approvePayment(orderId, payload) {
    return baseHTTP("put", `/app/user/payment/${orderId}/approve`, payload);
  },

  async cancelPayment(orderId, payload) {
    return baseHTTP("put", `/app/user/payment/${orderId}/cancel`, payload);
  },

  async getDashBIUrl() {
    return baseHTTP("get", `/app/user/dashboardBIUrl`);
  },
};
