import { cloneDeep, get, set } from "lodash";
import { compareObjects } from "@/utils/utils";
export default class BaseFilter {
  baseFilter = {};
  filter = {};
  temporary = {};
  pagination = {};
  filterKeys = [];
  touch = false;
  query = null;

  constructor(baseFilter = {}, pagination = {}) {
    const { page = 1, itemsPerPage = 50 } = pagination;
    this.pagination = {
      page,
      itemsPerPage,
    };
    this.baseFilter = Object.freeze(cloneDeep(baseFilter));
    this.filter = cloneDeep({ ...this.baseFilter });
    this.filterKeys = Object.keys(this.filter);
  }
  get comparator() {
    return compareObjects({ ...this.filter }, { ...this.temporary });
  }
  resetFilter() {
    for (const key of this.filterKeys) {
      this.set(key, this.baseFilter[key]);
      this.temporary[key] = this.baseFilter[key];
    }
    this.pagination = {
      page: 1,
      itemsPerPage: 50,
    };
    this.touch = false;
  }
  saveFilter() {
    if (this.comparator.hasChanges) {
      this.pagination.page = 1;
      this.touch = true;
    }
    const newFilter = {
      ...this.filter,
      ...cloneDeep(this.temporary),
    };
    for (const key of this.filterKeys) {
      if (newFilter[key]) this.set(key, newFilter[key]);
      else this.set(key, this.baseFilter[key]);
    }
  }
  setPagination(pagination = {}) {
    Object.assign(this.pagination, pagination);
  }
  getFilterCopy() {
    const filterCopy = cloneDeep(this.filter);
    if (filterCopy?.state === "all") filterCopy.state = "";
    if (!!filterCopy?.city && !Array.isArray(filterCopy?.city))
      filterCopy.city = [];
    return filterCopy;
  }
  get(key, defaultValue = null) {
    return get(this.filter, key, defaultValue);
  }
  set(key, value) {
    set(this.filter, key, value);
  }
}
