import store from "@/store";
import { toCurrency } from "@/utils/CurrencyUtils";
export class ProductEntity {
  price = 0;
  amount = 0;
  costPrice = 0;
  freeAmount = 0;
  recAmount = 0;
  automaticallyAdded = false;
  recAutomaticAmount = 0;
  center = null;
  product = {};

  constructor(product) {
    this.product = product;
    this.price = product.price;
    this.costPrice = product.costPrice;
    if (typeof product.amount == "number") this.amount = product.amount;
    if (typeof product.freeAmount == "number")
      this.freeAmount = product.freeAmount;
    if (typeof product.recAmount == "number")
      this.recAmount = product.recAmount;
    if (typeof product.recAutomaticAmount == "number")
      this.recAutomaticAmount = product.recAutomaticAmount;
    if (product.center) {
      this.center =
        typeof product.center == "string"
          ? product.center
          : product.center?._id;
    }
  }

  get basePrice() {
    return this.product.price;
  }

  get singlePrice() {
    return this.price;
  }

  get formattedSinglePrice() {
    return toCurrency(this.singlePrice);
  }

  get totalPrice() {
    return this.singlePrice * (this.amount - this.freeAmount);
  }

  get formattedTotalPrice() {
    return toCurrency(this.totalPrice);
  }

  get centerName() {
    return (
      store.getters["storeFlow/products/regionsEntity"]?.getCenter(this.center)
        ?.name || "indefinido"
    );
  }

  toRequestObject() {
    return {
      id: this.id,
      center: this.center,
      amount: this.amount,
      price: this.price,
      costPrice: this.costPrice,
      origin: this.origin || "ORIGIN",
      ncm: this.ncm || "NCM",
      basePrice: this.basePrice,
      recAmount: this.recAmount,
      freeAmount: this.freeAmount,
      recAutomaticAmount: this.recAutomaticAmount,
      name: this.name,
      productCode: this.productCode,
      type: this.type,
      potencia_WP: this.potencia_WP,
      futureStockDate: this.futureStockDate,
    };
  }

  toObject() {
    return {
      ...this.product,
      center: this.center,
      amount: this.amount,
      price: this.price,
      basePrice: this.basePrice,
      costPrice: this.costPrice,
      recAmount: this.recAmount,
      freeAmount: this.freeAmount,
      recAutomaticAmount: this.recAutomaticAmount,
    };
  }

  toRecommendationObject() {
    return {
      productCode: this.productCode,
      amount: this.amount,
      price: this.price,
      costPrice: this.costPrice,
      recAmount: this.recAmount,
      type: this.type,
      potencia_WP: this.potencia_WP,
      tipo_ligacao: this.tipo_ligacao,
      tipo_inversor: this.tipo_inversor,
      N_MPPTs: this.N_MPPTs,
      N_entrada_por_MPPT: this.N_entrada_por_MPPT,
      corrente_de_curto_circuito_A: this.corrente_de_curto_circuito_A,
      corrente_potencia_maxima_A: this.corrente_potencia_maxima_A,
    };
  }

  toInverterRecommendationObject(
    automaticRecommendationCurrent = "maximumPower"
  ) {
    const useSurgeCurrent = automaticRecommendationCurrent !== "maximumPower";

    return {
      productCode: this.productCode,
      amount: this.amount,
      price: this.price,
      costPrice: this.costPrice,
      potencia_WP: this.potencia_WP,
      tensao_potencia_maxima_V: this.tensao_potencia_maxima_V,
      tensao_de_circuito_aberto_V: this.tensao_de_circuito_aberto_V,
      corrente_maxima_A: useSurgeCurrent
        ? this.corrente_de_curto_circuito_A
        : this.corrente_potencia_maxima_A,
      tipo_inversor: this.tipo_inversor,
      min_tensao_de_entrada_V: this.min_tensao_de_entrada_V,
      potencia_nominal_de_saida_KW: this.potencia_nominal_de_saida_KW,
      tensao_nominal_de_saida_V: this.tensao_nominal_de_saida_V,
      tipo_ligacao: this.tipo_ligacao,
      N_MPPTs: this.N_MPPTs,
      max_potencia_de_entrada_KW: this.max_potencia_de_entrada_KW,
      max_tensao_de_entrada_por_string_V:
        this.max_tensao_de_entrada_por_string_V,
      N_entrada_por_MPPT: this.N_entrada_por_MPPT,
      inv_Compativeis: this.inv_Compativeis,
      max_corrente_por_MPPT_A: useSurgeCurrent
        ? this.max_corrente_curto_por_MPPT_A
        : this.max_corrente_potencia_maxima_por_MPPT_A,
      max_corrente_string_por_MPPT_A: useSurgeCurrent
        ? this.max_corrente_curto_string_por_MPPT_A
        : this.max_corrente_potencia_maxima_string_por_MPPT_A,
      comp_Max_Por_String: this.comp_Max_Por_String,
      comp_Min_Por_String: this.comp_Min_Por_String,
      potencia_Max_1_String: this.potencia_Max_1_String,
      potencia_Max_Acima_2_String: this.potencia_Max_Acima_2_String,
      potencia_Max_Acima_3_String: this.potencia_Max_Acima_3_String,
      potencia_Max_Acima_4_String: this.potencia_Max_Acima_4_String,
      potencia_Max_Por_String: this.potencia_Max_Por_String,
      quantidade_modular: this.quantidade_modular,
    };
  }

  //ProductInfo

  get id() {
    return this.product.id;
  }

  get model() {
    return this.product.model;
  }

  get productCode() {
    return this.product.productCode;
  }

  get ncm() {
    return this.product.ncm;
  }

  get origin() {
    return this.product.origin;
  }

  get name() {
    return this.product.name;
  }

  get fabricante() {
    return this.product.fabricante;
  }

  get modelo() {
    return this.product.modelo;
  }

  get price() {
    return this.product?.price;
  }

  get description() {
    return this.product.description;
  }

  get peso_KG() {
    return this.product.peso_KG;
  }

  get volume_M3() {
    return this.product.volume_M3;
  }

  get isToSell() {
    return this.product.isToSell;
  }

  get photo() {
    return this.product.photo;
  }

  get datasheet() {
    return this.product.datasheet;
  }

  get data_atualizacao() {
    return this.product.data_atualizacao;
  }

  get stock() {
    return this.product.stock || [];
  }

  get disponibilidade_condicional() {
    return this.product.disponibilidade_condicional;
  }

  get height_MM() {
    return this.product.height_MM;
  }

  get length_MM() {
    return this.product.length_MM;
  }

  get width_MM() {
    return this.product.width_MM;
  }

  get featured() {
    return this.product.featured;
  }

  get featuredEcommerce() {
    return this.product.featuredEcommerce;
  }

  get type() {
    return this.product.type;
  }

  get futureStockDate() {
    return this.product.futureStockDate;
  }

  // Module

  get potencia_WP() {
    return this.product.potencia_WP;
  }

  get eficiencia() {
    return this.product.eficiencia;
  }

  get tensao_de_circuito_aberto_V() {
    return this.product.tensao_de_circuito_aberto_V;
  }

  get corrente_de_curto_circuito_A() {
    return this.product.corrente_de_curto_circuito_A;
  }

  get tensao_potencia_maxima_V() {
    return this.product.tensao_potencia_maxima_V;
  }

  get corrente_potencia_maxima_A() {
    return this.product.corrente_potencia_maxima_A;
  }

  get tipo_de_celula() {
    return this.product.tipo_de_celula;
  }

  // inverter
  get tipo_inversor() {
    return this.product.tipo_inversor;
  }

  get tipo_ligacao() {
    return this.product.tipo_ligacao;
  }

  get max_potencia_de_entrada_KW() {
    return this.product.max_potencia_de_entrada_KW;
  }

  get max_tensao_de_entrada_por_string_V() {
    return this.product.max_tensao_de_entrada_por_string_V;
  }

  get min_tensao_de_entrada_V() {
    return this.product.min_tensao_de_entrada_V;
  }

  get min_faixa_de_tensao_por_MPPT_V() {
    return this.product.min_faixa_de_tensao_por_MPPT_V;
  }

  get max_faixa_de_tensao_por_MPPT_V() {
    return this.product.max_faixa_de_tensao_por_MPPT_V;
  }

  get N_MPPTs() {
    return this.product.N_MPPTs;
  }

  get potencia_nominal_de_saida_KW() {
    return this.product.potencia_nominal_de_saida_KW;
  }

  get tensao_nominal_de_saida_V() {
    return this.product.tensao_nominal_de_saida_V;
  }

  get max_corrente_de_saida_A() {
    return this.product.max_corrente_de_saida_A;
  }

  get N_entrada_por_MPPT() {
    return this.product.N_entrada_por_MPPT;
  }

  get quantidade_modular() {
    return this.product.quantidade_modular;
  }

  get max_corrente_potencia_maxima_string_por_MPPT_A() {
    return this.product.max_corrente_potencia_maxima_string_por_MPPT_A;
  }

  get max_corrente_potencia_maxima_por_MPPT_A() {
    return this.product.max_corrente_potencia_maxima_por_MPPT_A;
  }

  get max_corrente_curto_por_MPPT_A() {
    return this.product.max_corrente_curto_por_MPPT_A;
  }

  get max_corrente_curto_string_por_MPPT_A() {
    return this.product.max_corrente_curto_string_por_MPPT_A;
  }

  get inv_Compativeis() {
    return this.product.inv_Compativeis;
  }

  get comp_Min_Por_String() {
    return this.product.comp_Min_Por_String;
  }

  get comp_Max_Por_String() {
    return this.product.comp_Max_Por_String;
  }

  get potencia_Max_Por_String() {
    return this.product.potencia_Max_Por_String;
  }

  get potencia_Max_Acima_2_String() {
    return this.product.potencia_Max_Acima_2_String;
  }

  get potencia_Max_Acima_3_String() {
    return this.product.potencia_Max_Acima_3_String;
  }

  get potencia_Max_Acima_4_String() {
    return this.product.potencia_Max_Acima_4_String;
  }

  //String box

  /**
   * @return {null|Array}
   */
  get n_entradas() {
    return this.product.n_entradas;
  }

  /**
   * @return {null|Array}
   */
  get n_saidas() {
    return this.product.n_saidas;
  }

  get tensao_max_string_V() {
    return this.product.tensao_max_string_V;
  }

  get corrente_max_string_A() {
    return this.product.corrente_max_string_A;
  }

  get grau_protecao() {
    return this.product.grau_protecao;
  }

  get category() {
    return this.product.category;
  }

  get priceByWp() {
    return Number(this.singlePrice / (this.product.potencia_WP || 0));
  }

  get priceByWpFormatted() {
    return this.priceByWp.toBRNumber();
  }

  get priceByKw() {
    return Number(
      this.singlePrice / (this.product.potencia_nominal_de_saida_KW || 0)
    );
  }

  get priceByKwFormatted() {
    return Window.invertFormatMoney(this.priceByKw);
  }

  setAvailableCenter(centerOrder = []) {
    for (const center of centerOrder) {
      if (!this.isAvailableInCenter(center)) continue;
      this.center = center;
      break;
    }
  }

  isAvailableInCenter(center) {
    return this.stock.find(
      (stock) => stock.center === center && stock.available
    );
  }

  /**
   * Calculates the input power of an inverter.
   *
   * @return {number} The input power of the inverter, calculated as the product of the maximum input power in W and the amount of inverters.
   *                 Returns 0 if the product type is not "inverter" or if the inverter type is "otimizador".
   */

  get inverterInputPower() {
    if (this.type === "inverter" && this.tipo_inversor !== "otimizador") {
      return (this.max_potencia_de_entrada_KW || 0) * (this.amount || 0);
    }
    return 0;
  }

  /**
   * Calculates the output power of an inverter.
   *
   * @return {number} The output power of the inverter, calculated as the product of the nominal power of output in kW and the amount of inverters.
   */
  get inverterOutputPower() {
    if (this.type === "inverter") {
      return (this.potencia_nominal_de_saida_KW || 0) * (this.amount || 0);
    }
    return 0;
  }

  get modulePower() {
    if (this.type === "module") {
      return (this.potencia_WP || 0) * (this.amount || 0);
    }
    return 0;
  }

  errorIncompatibleModulesByCurrent(
    productEntities = [],
    automaticRecommendationCurrent = "maximumPower"
  ) {
    const check =
      this.type === "inverter" &&
      this.amount > 0 &&
      this.tipo_inversor !== "solaredge";
    if (!check) {
      return "";
    }

    const moduleEntities = productEntities.filter(
      (p) => p.type === "module" && p.amount > 0
    );

    const [moduleKey, inverterKey, currentTypeTranslated] =
      automaticRecommendationCurrent === "maximumPower"
        ? [
            "corrente_potencia_maxima_A",
            "max_corrente_potencia_maxima_string_por_MPPT_A",
            "entrada",
          ]
        : [
            "corrente_de_curto_circuito_A",
            "max_corrente_curto_string_por_MPPT_A",
            "curto-circuito",
          ];

    const incompatibleModules = moduleEntities.filter(
      (module) => Number(module[moduleKey]) > Math.max(this[inverterKey])
    );
    if (incompatibleModules.length === 0) {
      return "";
    }

    const moduleNamesConcat = incompatibleModules.reduce(
      (previous, current, index) => {
        const separator =
          index == incompatibleModules.length - 1 ? " e " : ", ";
        return previous ? previous + separator + current.name : current.name;
      },
      ""
    );

    return `ATENÇÃO! A máxima corrente de ${currentTypeTranslated} do inversor ${
      this.name
    } é inferior à corrente máxima ${
      incompatibleModules.length > 1 ? "dos módulos" : "do módulo"
    } ${moduleNamesConcat}.`;
  }
}
